import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/fp/isEmpty';

import RichText from '~/shared/components/RichText';
import * as inline from '~/shared/components/RichText/configs/inline';

const columnSizes = {
  6: ({ theme }) => css`
    ${theme.mq.kiloToMega} {
      grid-template-columns:
        minmax(30%, 2fr)
        minmax(14%, 1fr)
        minmax(14%, 1fr)
        minmax(14%, 1fr)
        minmax(14%, 1fr)
        minmax(14%, 1fr);
    }

    ${theme.mq.megaToGiga} {
      grid-template-columns:
        minmax(234px, 2fr)
        minmax(110px, 1fr)
        minmax(110px, 1fr)
        minmax(110px, 1fr)
        minmax(110px, 1fr)
        minmax(110px, 1fr);
    }

    ${theme.mq.giga} {
      grid-template-columns:
        minmax(289px, 2fr)
        minmax(135px, 1fr)
        minmax(135px, 1fr)
        minmax(135px, 1fr)
        minmax(135px, 1fr)
        minmax(135px, 1fr);
    }
  `,
  5: ({ theme }) => css`
    ${theme.mq.kiloToMega} {
      grid-template-columns:
        minmax(36%, 2fr)
        minmax(16%, 1fr)
        minmax(16%, 1fr)
        minmax(16%, 1fr)
        minmax(16%, 1fr);
    }

    ${theme.mq.megaToGiga} {
      grid-template-columns:
        minmax(272px, 2fr)
        minmax(128px, 1fr)
        minmax(128px, 1fr)
        minmax(128px, 1fr)
        minmax(128px, 1fr);
    }

    ${theme.mq.giga} {
      grid-template-columns:
        minmax(292px, 2fr)
        minmax(168px, 1fr)
        minmax(168px, 1fr)
        minmax(168px, 1fr)
        minmax(168px, 1fr);
    }
  `,
  4: ({ theme }) => css`
    ${theme.mq.kiloToMega} {
      grid-template-columns:
        minmax(40%, 2fr)
        minmax(20%, 1fr)
        minmax(20%, 1fr)
        minmax(20%, 1fr);
    }

    ${theme.mq.megaToGiga} {
      grid-template-columns:
        minmax(320px, 2fr)
        minmax(160px, 1fr)
        minmax(160px, 1fr)
        minmax(160px, 1fr);
    }

    ${theme.mq.giga} {
      grid-template-columns:
        minmax(392px, 2fr)
        minmax(196px, 1fr)
        minmax(196px, 1fr)
        minmax(196px, 1fr);
    }
  `,
  3: () => css`
    grid-template-columns:
      minmax(50%, 2fr)
      minmax(25%, 1fr)
      minmax(25%, 1fr);
  `,
  2: ({ theme }) => css`
    ${theme.mq.kiloToMega} {
      grid-template-columns:
        minmax(60%, 2fr)
        minmax(40%, 1fr);
    }

    ${theme.mq.megaToGiga} {
      grid-template-columns:
        minmax(50%, 2fr)
        minmax(50%, 1fr);
    }

    ${theme.mq.giga} {
      grid-template-columns:
        minmax(60%, 2fr)
        minmax(40%, 1fr);
    }
  `,
};
const tableStyles = ({ colsNumber, theme }) => css`
  width: 100%;

  tr {
    display: grid;
    width: 100%;
    align-items: end;

    ${columnSizes[colsNumber]({ theme })}
  }
`;

const StyledTable = styled('table')(tableStyles);

const tableHeadingStyles = ({ theme }) => css`
  tr {
    ${theme.typography.body.two}
    color: var(--cui-fg-normal);
    font-weight: bold;
    text-align: left;
    border-bottom: 2px solid var(--cui-border-strong);
  }

  th {
    padding: ${theme.spacings.kilo} ${theme.spacings.mega}
      ${theme.spacings.kilo} 0;
  }

  th:last-child {
    padding-right: 0;
  }
`;

const Thead = styled('thead')(tableHeadingStyles);

const tableBodyStyles = ({ theme }) => css`
  tr {
    padding: ${theme.spacings.mega} 0;
    border-bottom: 1px solid var(--cui-border-normal);
    ${theme.typography.body.one}
  }

  tr td {
    align-self: center;
    padding-right: ${theme.spacings.mega};
  }

  tr:last-of-type {
    border-bottom: 0;
  }

  tr td:last-of-type {
    padding-right: 0;
  }
`;

const Tbody = styled('tbody')(tableBodyStyles);

function GenericTable({ headings, rows }) {
  return (
    <StyledTable colsNumber={headings.length}>
      <Thead>
        <tr>
          {headings.map((tableHeading) => (
            <th key={tableHeading}>{tableHeading}</th>
          ))}
        </tr>
      </Thead>
      <Tbody>
        {rows.map((tableRow) => (
          <tr key={tableRow.id}>
            {tableRow.tableCells.map((tableCell) =>
              tableCell && !isEmpty(tableCell.content) ? (
                <td key={tableCell.id + tableRow.id}>
                  <RichText
                    renderNode={inline.createRenderNode(inline.ONE)}
                    renderMark={inline.createRenderMark(inline.ONE)}
                    richText={tableCell.content}
                  />
                </td>
              ) : (
                <td key={`td-${tableRow.id}`}>-</td>
              ),
            )}
          </tr>
        ))}
      </Tbody>
    </StyledTable>
  );
}

GenericTable.propTypes = {
  headings: PropTypes.arrayOf(PropTypes.string),
  rows: PropTypes.arrayOf(PropTypes.object),
};

export default GenericTable;
