import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import isEmpty from 'lodash/fp/isEmpty';

import Anchor from '~/shared/components/Anchor';
import RichText from '~/shared/components/RichText';
import * as inline from '~/shared/components/RichText/configs/inline';
import ExpansionPanelList from '~/shared/components/ExpansionPanelList';

const expansionPanelListStyles = ({ theme }) => css`
  ${theme.mq.untilKilo} {
    padding-left: 0;
    padding-right: 0;
  }

  dt,
  dd {
    border: none;
    padding-left: 0;
    padding-right: 0;
  }

  svg path {
    fill: var(--cui-fg-accent);
  }
`;

const expansionPanelBorderStyles = () => css`
  dd {
    border-bottom: 1px solid var(--cui-border-strong);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  dd:last-of-type {
    border: none;
  }
`;

const StyledExpansionPanelList = styled(ExpansionPanelList)(
  expansionPanelListStyles,
  expansionPanelBorderStyles,
);

const rowStyles = ({ theme }) => css`
  padding: ${theme.spacings.kilo} 0;
  border-bottom: 1px solid var(--cui-border-normal);
  ${theme.typography.body.one}
  display: grid;
  border-collapse: collapse;
  width: 100%;
  align-items: center;

  grid-template-columns:
    minmax(65%, 2fr)
    minmax(35%, 1fr);
`;

const borderWithLinkStyles = ({ hasLink }) =>
  !hasLink &&
  css`
    &:last-of-type {
      border-bottom: 0;
    }
  `;

const Row = styled('div')(rowStyles, borderWithLinkStyles);

const valueWrapperStyles = () => css`
  justify-self: end;
  text-align: right;
`;

const ValueWrapper = styled('div')(valueWrapperStyles);

const anchorStyles = ({ theme }) => css`
  padding: ${theme.spacings.mega} 0 ${theme.spacings.tera};
  font-weight: 700;
  display: inline-block;
`;

const StyledAnchor = styled(Anchor)(anchorStyles);

const mapExpansionPanel = (row, headings) => {
  const { tableCells, linkOptimizelyFullStackClickEvents } = row;

  if (isEmpty(tableCells)) {
    return {};
  }

  const [nameTableCell, ...restTableCells] = tableCells;
  const [, ...restHeadings] = headings;

  const { contentType, id, name, linkLabel, linkUrl, linkTrackingId } = row;

  const trackingContentEntry = {
    contentType,
    contentEntryName: name,
    contentEntryId: id,
  };

  const hasLink = linkLabel && linkUrl;

  return {
    headline: (
      <RichText
        renderNode={inline.createRenderNode(inline.ONE, trackingContentEntry, {
          embeddedLink: { changeToVariable: true },
        })}
        renderMark={inline.createRenderMark(inline.ONE)}
        richText={nameTableCell.content}
      />
    ),
    children: (
      <Fragment>
        {restTableCells.map((tableCell, i) => (
          <Row key={id + i} hasLink={hasLink}>
            <div>{restHeadings[i]}</div>
            <ValueWrapper>
              {tableCell && !isEmpty(tableCell.content) ? (
                <RichText
                  renderNode={inline.createRenderNode(
                    inline.ONE,
                    trackingContentEntry,
                  )}
                  renderMark={inline.createRenderMark(inline.ONE)}
                  richText={tableCell.content}
                />
              ) : (
                <div>-</div>
              )}
            </ValueWrapper>
          </Row>
        ))}
        {hasLink ? (
          <StyledAnchor
            trackingContentEntry={trackingContentEntry}
            trackingId={linkTrackingId}
            href={linkUrl}
            optimizelyFullStackClickEvents={linkOptimizelyFullStackClickEvents}
          >
            {linkLabel}
          </StyledAnchor>
        ) : null}
      </Fragment>
    ),
  };
};

function MobileTable({ rows, headings }) {
  return (
    <StyledExpansionPanelList
      openPanelId={0}
      expansionPanels={rows.map((row) => mapExpansionPanel(row, headings))}
    />
  );
}

MobileTable.propTypes = {
  headings: PropTypes.arrayOf(PropTypes.string),
  rows: PropTypes.arrayOf(PropTypes.object),
};

export default MobileTable;
