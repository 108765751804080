import { getVariationComponent } from '~/shared/services/optimizely/OptimizelyVariationsService';

export const filterEmptyStrings = (headings) => headings.filter(Boolean);

export const getTableCells = (colsNumber, tableCells = []) => {
  const { length } = tableCells;

  if (length >= colsNumber) {
    return tableCells.slice(0, colsNumber);
  }

  const difference = colsNumber - length;
  return tableCells.concat(new Array(difference).fill());
};

export const getRows = (rows, colsNumber, experiments = []) =>
  rows.map((originalRow) => {
    const row = getVariationComponent(originalRow, experiments);

    const { tableCells } = row;

    const mappedTableCells = getTableCells(colsNumber, tableCells);

    return {
      ...row,
      tableCells: mappedTableCells.map((originalTableCell) =>
        getVariationComponent(originalTableCell, experiments),
      ),
    };
  });
