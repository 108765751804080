import React from 'react';
import { Grid } from '@sumup/circuit-ui/legacy';
import isEmpty from 'lodash/fp/isEmpty';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import * as TableService from './TableService';
import GenericTable from './components/GenericTable';
import MobileTable from './components/MobileTable';

import { ALIGNMENT, VIEWPORTS } from '~/shared/constants';
import useViewportName from '~/shared/hooks/use-viewport-name';
import useOptimizelyData from '~/shared/services/optimizely/use-optimizely-data';
import SectionHeader from '~/shared/components/SectionHeader';

const wrapperStyles = ({ colsNumber, theme }) =>
  (colsNumber === 2 || colsNumber === 3) &&
  css`
    ${theme.mq.giga} {
      width: calc(100% * 2 / 3);
      margin: 0 auto;
    }
  `;

const StyledWrapper = styled('div')(wrapperStyles);

function Table({
  tableHeadings,
  tableRows,
  headline,
  content,
  alignment = ALIGNMENT.LEFT,
  index,
}) {
  const { experiments } = useOptimizelyData();
  const viewportName = useViewportName();

  if (isEmpty(tableHeadings) || isEmpty(tableRows)) {
    return null;
  }

  const headings = TableService.filterEmptyStrings(tableHeadings);
  const rows = TableService.getRows(tableRows, headings.length, experiments);

  return (
    <Grid data-elbcontext="component:table">
      <StyledWrapper colsNumber={headings.length}>
        <SectionHeader
          headline={headline}
          content={content}
          alignment={alignment}
          index={index}
        />
        {viewportName === VIEWPORTS.MOBILE ? (
          <MobileTable rows={rows} headings={headings} />
        ) : (
          <GenericTable rows={rows} headings={headings} />
        )}
      </StyledWrapper>
    </Grid>
  );
}

Table.propTypes = {
  tableHeadings: PropTypes.arrayOf(PropTypes.string),
  tableRows: PropTypes.arrayOf(PropTypes.object),
  headline: PropTypes.string,
  content: PropTypes.object,
  alignment: PropTypes.string,
  index: PropTypes.number,
};

export default Table;
